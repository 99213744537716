<template>
  <div>
    <v-dialog v-model="saveConfirmationDialog" scrollable persistent :max-width="formWidth">
      <v-card>
        <v-card-title style="display: block" class="pb-1 pr-8">
          <v-icon x-large class="mr-3">mdi-square-edit-outline</v-icon>
          {{ `Confirmar ${formTitle.toLowerCase()}` }}
          <v-btn @click="saveConfirmationDialog = false" style="position: absolute; right: 5px; top: 5px" icon>
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 form_container">
          <span v-if="saveConfirmation === true">Deseja realmente concluir está ação?</span>
          <span v-else v-html="saveConfirmation"></span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="secondary" class="px-5" dark depressed @click="saveConfirmationDialog = false">
            Não
          </v-btn>
          <v-btn
            :disabled="isBlocked"
            v-if="!readOnly"
            color="primary"
            :dark="!isBlocked"
            depressed
            class="px-5 ml-3"
            @click="doSave()"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="saveDialog" scrollable persistent :max-width="formWidth">
      <v-card>
        <v-card-title style="display: block" class="pb-1 pr-8">
          <v-icon x-large class="mr-3">mdi-square-edit-outline</v-icon>
          {{ formTitle }}
          <v-btn @click="doExitForm()" style="position: absolute; right: 5px; top: 5px" icon>
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 form_container">
          <v-container>
            <v-row class="ml-n4 mr-0" v-if="saveDialog">
              <template v-for="(i, k) in cols">
                <input-v
                  class="pl-4"
                  :hideInform="!showField(i.hideInform) "
                  :key="k"
                  v-model="selectedItem[i.key]"
                  :valid.sync="i.valid"
                  :block.sync="i.block"
                  :label="i"
                  :opts="opts"
                  :editable="!isSend && i.editable != false && !readOnly"
                  :colSize="i.colSize"
                  :commentForField.sync="selectedItem[i.commentForField]"
                  @changed="valueChanged(i.valueChanged, selectedItem[i.key]); $emit(`update:${i.key}`, $event)"
                  @update:block="updateBlock()"
                ></input-v>
              </template>
            </v-row>
          </v-container>
          <v-alert
            v-if="isErrorMessage"
            dense
            style="font-size: 13px"
            text
            type="error"
          >
            {{ isErrorMessage }}
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="pr-5" dark depressed @click="doExitForm()">
            <v-icon left>mdi-chevron-left</v-icon>
            {{ readOnly ? 'Voltar' : 'Cancelar' }}
          </v-btn>
          <v-btn
            :disabled="isBlocked"
            v-if="!readOnly"
            color="primary"
            :dark="!isBlocked"
            depressed
            class="px-5 ml-3"
            @click="saveConfirmation ? saveConfirmationDialog = true : doSave()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" scrollable persistent max-width="500">
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon color="red" x-large class="mr-3">mdi-alert-circle-outline</v-icon>Exclusão
          <v-btn
            @click="deleteDialog = false"
            icon
            style="position: absolute; right: 5px; top: 5px"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <template v-if="confirmDeleteMessage">
            {{ confirmDeleteMessage(selectedItem) }}
          </template>
          <template v-else>
            Deseja excluir o registro:
            <b>{{ filterDescription(descriptionProperty) }}</b>?
          </template>
          <v-alert
            v-if="isErrorMessage"
            dense
            style="font-size: 13px"
            text
            type="error"
          >
            {{ isErrorMessage }}
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="pxF-5"
            dark
            depressed
            @click="deleteDialog = false"
          >
            Não
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="
              deleteDialog = false;
              doDelete();
            "
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- TODO tinha um hasExportCSV=false no table-v abaixo -->
    <!-- eu não sei por que deveria ser falso ou de onde vem -->
    <!-- precisava que hasExportCSV viesse de um prop para que o relatório assincrono em Materiais Detalhados (e depois serviço terceiros detalhado) funcionasse -->
    <table-v
      :actionBarButtons="computedActionBarButtons"
      :asyncExportType="asyncExportType"
      :beneficio="beneficioFilter"
      :cols="cols"
      :disableAllItensPerPage="serverPagination"
      :disablePagination="disablePagination"
      :disableTableSaveButton="disableTableSaveButton"
      :expandSubItem="expandSubItem"
      :expandContextMenu="!!expandContextMenu"
      :hasFilter="hasFilter"
      :hasExportCSV="hasExportCSV"
      :hasExportXLSX="hasExportXLSX"
      :height="height"
      :lastRowData="lastRowData"
      :loading="!loaded"
      :monthlyFilter="monthlyFilter"
      :oneMonthFilter="oneMonthFilter"
      :opts="opts"
      :quarterFilter="quarterFilter"
      :resourceId="resourceId"
      :rows="rows"
      :selectedToCompare="selectedToCompare"
      :selection="selection"
      :totalItems="totalItems"
      :yearFilter="yearFilter"
      :yearFilterConfig="yearFilterConfig"
      :selectionCol="selectionCol"
      :hideCheckAll="hideCheckAll"
      :dayFilter="dayFilter"
      @changePage="onPaginate"
      @click="actContext"
      @contextmenu="actContext"
      @load="doLoad()"
      @rowstoprint="rowsToPrint"
      @save="saveFromTable"
      @update:beneficio="updateBeneficio"
      @update:monthlyFilter="updateMonthlyFilter"
      @update:oneMonthFilter="updateOneMonthFilter"
      @update:quarterFilter="updateFilterTrimestre"
      @update:yearFilter="updateYearFilter"
      @update:dayFilter="updateDayFilter"
      @update:selection="$emit('update:selection', $event)"
    >
      <slot></slot>
    </table-v>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import getRecursiveProperty from "@/helpers/getRecursiveProperty";
import toQueryParams from '@/helpers/objectToQueryParams';

export default {
  components: {
    "table-v": () => import("@/components/table-v.vue"),
    "input-v": () => import("@/components/input-v.vue"),
  },
  props: {
    resourceUrl: {},
    resourceListProperty: {},
    customResource: {},
    descriptionProperty: { default: "id" },
    confirmDeleteMessage: { type: Function },
    resourceId: { default: "id" },
    cols: {},
    opts: {},
    contextOptions: {},
    expandContextMenu: { type: [Boolean, Array], default: false },
    hasNewButton: { default: true },
    newButtonText: { default: "Adicionar" },
    canEdit: { default: true },
    canView: { default: false },
    canDelete: { default: true },
    hasFilter: { default: true },
    hasExportCSV: { default: true },
    hasExportXLSX: { default: true },
    hasQuarterFilter: { type: Boolean, default: false },
    hasMonthlyFilter: { type: Boolean, default: false },
    hasOneMonthFilter: { type: Boolean, default: false },
    hasYearFilter: { type: [Boolean, Object], default: false },
    hasBeneficioFilter: { type: Boolean, default: false },
    noReloadOnSave: { type: Boolean, default: false },
    noReloadOnDelete: { type: Boolean, default: false },
    disableTableSaveButton: { type: [Boolean, Function], default: false },
    saveConfirmation: { type: [Boolean, String], default: false },
    disableActContextOnClick: { type: Boolean, default: false },
    disableContextMenu: { type: Boolean, default: false },
    filterQuery: {},
    selectedResourceId: {},
    actionBarButtons: { default: () => [] },
    filterForDescription: {},
    formTitle: { default: "Cadastro" },
    formWidth: { default: 600 },
    serverPagination: { type: Boolean, default: false },
    height: { default: undefined },
    selectedToCompare: { default: undefined },
    expandSubItem: { default: false },
    lastRowData: {},
    asyncExportType: { type: String },
    selectionCol: { type: Boolean, default: false },
    hideCheckAll: { type: Boolean, default: false },
    selection: { type: Array, default: () => [] },
    disablePagination: { type: Boolean, default: false },
    hasDayFilter: { type: Boolean, default: false },
  },
  data: function () {
    // TODO esta inicialização toda vai cair fora quando os valores dos filtros vierem do Vuex
    const anoBase = this.getFilters('anoBase');
    const monthly = this.getFilters('monthly');

    return {
      isSend: false,
      readOnly: false,
      saveConfirmationDialog: false,
      saveDialog: false,
      deleteDialog: false,
      selectedItem: {},
      isErrorMessage: "",
      loaded: false,
      rows: [],
      blocks: [],
      totalItems: undefined,
      filterTrimestre: {
        trimestreIni: 1,
        anoBase,
      },
      competencia: monthly,
      anoBase,
      beneficio: 1,
    };
  },
  computed: {
    ...mapGetters(['getClientSidePaginationItemsPerPage', 'getServerSidePaginationItemsPerPage']),
    userCanCreate: function () {
      return (this.userHasAccessToAction('create') || this.userHasAccessToAction('geral')) && this.hasNewButton;
      // return this.userHasAccessToAction('create');
    },
    userCanDelete: function () {
      return (this.userHasAccessToAction('delete')  || this.userHasAccessToAction('geral')) && this.canDelete;
      // return this.userHasAccessToAction('delete');
    },
    userCanEdit: function () {
      return (this.userHasAccessToAction('update')  || this.userHasAccessToAction('geral')) && this.canEdit;
      // return this.userHasAccessToAction('update');
    },
    isBlocked: function () {
      return this.blocks.some((block) => block);
    },
    client: function () {
      return this.getClient();
    },
    computedActionBarButtons: function () {
      const bar = [];
      if (this.userCanCreate) {
        bar.push({
          text: this.newButtonText,
          icon: "mdi-plus-box-outline",
          action: () => {
            this.selectedItem = {};
            this.$emit("onOpenFormDialog", this.selectedItem);
            this.saveDialog = true;
          },
        });
      }

      return bar.concat(this.actionBarButtons);
    },
    resource: function () {
      return this.customResource
        ? this.customResource
        : this.apiResource(this.resourceUrl);
    },
    quarterFilter: function () {
      return this.hasQuarterFilter ? this.filterTrimestre : false;
    },
    monthlyFilter: function () {
      return this.hasMonthlyFilter ? this.competencia : false;
    },
    dayFilter: function () {
      return this.hasDayFilter ? this.competencia : false;
    },
    oneMonthFilter: function () {
      return this.hasOneMonthFilter ? this.competencia[0] : false;
    },
    yearFilter: function () {
      return this.hasYearFilter ? this.anoBase : false;
    },
    yearFilterConfig: function () {
      return typeof this.hasYearFilter === 'object' ? this.hasYearFilter : {};
    },
    beneficioFilter: function () {
      return this.hasBeneficioFilter ? this.beneficio : false;
    },
    defaultOptions: function () {
      const options = [];

      if (this.contextOptions) {
        options.push(...this.contextOptions);

        if (this.userCanEdit || this.userCanDelete) {
          options.push({ name: "sep" });
        }
      }

      if (this.userCanEdit) {
        options.push({
          name: "Editar",
          limit: 1,
          icon: "",
          cb: (e) => {
            this.selectedItem = { ...e };
            this.$emit("onOpenFormDialog", this.selectedItem);
            this.saveDialog = true;
          },
        });
      }

      if (this.canView) {
        options.push({
          name: "Visualizar",
          limit: 1,
          icon: "",
          cb: (e) => {
            this.selectedItem = { ...e };
            this.$emit("onOpenFormDialog", this.selectedItem);
            this.saveDialog = true;
            this.readOnly = true;
          },
        });
      }

      if (this.userCanDelete) {
        options.push({
          name: "Excluir",
          limit: -1,
          icon: "",
          cb: (e) => {
            this.selectedItem = { ...e };
            this.deleteDialog = true;
          },
        });
      }

      return options;
    },
    hasFilterableColumn: function() {
      return this.cols.some(({ filterable }) => filterable);
    },
  },
  created: function () {
    // TODO esta inicialização toda vai cair fora quando os valores dos filtros vierem do Vuex
    const monthly = this.getFilters('monthly') || [];
    const monthlyIni = moment(monthly[0]);
    const monthlyFim = moment(monthly[1]);
    const ini = monthlyIni.isValid() ? monthlyIni : moment().startOf("quarter");
    const fim = monthlyFim.isValid() ? monthlyFim : moment().endOf("quarter");
    this.competencia = [ini.format("YYYY-MM"), fim.format("YYYY-MM")];

    const anoBase = this.getFilters('anoBase') || moment().format('YYYY');
    this.filterTrimestre.anoBase = anoBase;

    if (!this.serverPagination) {
      this.doLoad();
    }
  },
  methods: {
    updateBlock: function () {
      this.blocks = this.cols
        .filter((i) => this.showField(i.hideInform) && i.editable !== false)
        .map(({ block }) => block);
    },
    valueChanged: function (valueChanged, value) {
      if(valueChanged){
        valueChanged(value);
      }
    },
    rowsToPrint: function (resolve, reject) {
      if (this.serverPagination) {
        this.resource.get({ query: this.filterQuery }).then((result) => {
          if (result.error) {
            reject(result.error);
          } else {
            resolve(result);
          }
        });
      } else {
        resolve(this.rows);
      }
    },
    showField: function (hideInform) {
      return this.isFunction(hideInform) ? !hideInform(this.selectedItem) : !hideInform;
    },
    filterDescription: function (value) {
      if(this.isFunction(value)){
        return value(this.selectedItem);
      }else if (this.filterForDescription) {
        return Vue.filter(this.filterForDescription)(this.selectedItem[value]);
      }
      return getRecursiveProperty(value, this.selectedItem);
    },
    isFunction: function(param) {
      return param instanceof Function
    },
    isValidForm: function () {
      var invalid = this.cols
        .filter((i) => this.showField(i.hideInform) && i.editable !== false && i.valid)
        .map((l) => {
          return l.valid() ? "VALIDO" : "INVÁLIDO";
        })
        .find((valid) => valid == "INVÁLIDO");
      return !invalid;
    },
    saveFromTable: function (item) {
      this.selectedItem = item;
      this.doSave(true, 'table');
    },
    doSave: function (dontValidate = false, from = 'dialog') {
      if (dontValidate || this.isValidForm()) {
        if (this.isSend === false) {
          this.isSend = true;
          this.isErrorMessage = null;
          this.resource
            .save(this.selectedItem, this.selectedItem[this.resourceId])
            .then((a) => {
              if (a.error) {
                this.isErrorMessage = a.error;
                this.isSend = false;
              } else {
                this.doExitForm();
                if (from !== 'table') {
                  setTimeout(this.doLoad, 1000);
                }
              }
            })
            .catch(this.doExitForm);
        }
      } else {
        this.isErrorMessage = "Campos do formulário estão inválidos.";
      }
    },
    doExitForm: function () {
      this.saveConfirmationDialog = false;
      this.saveDialog = false;
      this.selectedItem = {};
      this.isErrorMessage = null;
      this.isSend = false;
      this.readOnly = false;
    },
    onPaginate: function (options) {
      if (this.serverPagination || this.hasFilterableColumn) {
        this.doLoad(toQueryParams(options));
        this.$emit('update:selection', []);
      }
    },
    doLoad: function (pagingQuery) {
      this.loaded = false;
      let query = [];

      if (this.filterQuery) {
        query.push(this.filterQuery);
      }

      if (pagingQuery) {
        query.push(pagingQuery);
      } else if (this.serverPagination) {
        const itensPerPage = this.serverPagination ? this.getServerSidePaginationItemsPerPage : this.getClientSidePaginationItemsPerPage || 10;
        query.push(`page=1&per_page=${itensPerPage}`);
      }

      if (this.hasQuarterFilter) {
        const { trimestreIni, trimestreFim, anoBase } = this.filterTrimestre;
        const triQuery = `ano=${anoBase}&trimestreIni=${trimestreIni}${trimestreFim ? `&trimestreFim=${trimestreFim}` : ''}`;
        query.push(triQuery);
      }

      if ((this.hasMonthlyFilter || this.hasOneMonthFilter) && this.competencia?.lenght > 1) {
        return;
      }

      if (this.hasOneMonthFilter) {
        const [ competencia ] = this.competencia;
        const competenciaQuery = `competencia=${competencia}`;
        query.push(competenciaQuery);
      } else if (this.hasMonthlyFilter && this.competencia.length > 1) {
        const [ competenciaIni, competenciaFim ] = this.competencia;
        const competenciaQuery = `competenciaIni=${competenciaIni}&competenciaFim=${competenciaFim}`;
        query.push(competenciaQuery);
      }

      if(this.hasDayFilter) {
        const [ competenciaIni, competenciaFim ] = this.competencia;
        const competenciaQuery = `competenciaIni=${competenciaIni}&competenciaFim=${competenciaFim}`;
        query.push(competenciaQuery);
      }

      if (this.hasYearFilter) {
        if (!this.anoBase) {
          return;
        }

        const yearQuery = `ano=${this.anoBase}`;
        query.push(yearQuery);
      }

      if (this.hasBeneficioFilter) {
        query.push(`trabalho=${this.beneficio}`);
      }

      this.resource.get({ query: query.join('&') }).then((result) => {
        this.loaded = true;
        if (!result.error) {
          if (this.resourceListProperty) {
            this.rows = result[this.resourceListProperty];
            Object.keys(this.opts).forEach((key) => {
              if (this.opts[key] && !Array.isArray(this.opts[key])) {
                this.opts[key] = result[this.opts[key].resourceProperty];
              }
            });
          } else {
            if (this.serverPagination && result.data) {
              this.rows = result.data;
              this.totalItems = result.meta?.total || result.total;
            } else {
              this.rows = result;
            }
          }
          this.$emit("loadedRows", this.rows);
          if (this.selectedResourceId && this.userCanEdit) {
            var selectedResource = this.rows.find(
              (r) => r[this.resourceId] == this.selectedResourceId
            );
            if (selectedResource) {
              this.selectedItem = { ...selectedResource };
              this.$emit("onOpenFormDialog", this.selectedItem);
              this.saveDialog = true;
            }
          }
        }
      });
    },
    doDelete: function () {
      this.isErrorMessage = null;
      this.isSend = true;
      this.resource.delete(this.selectedItem[this.resourceId]).then((a) => {
        if (a.error) {
          this.isErrorMessage = a.error;
          this.isSend = false;
        } else {
          this.doExitForm();
          this.$notify({
            group: "geral",
            duration: 7000,
            type: "success",
            title: "Excluido com sucesso.",
            text: "Registro foi excluido com sucesso.",
          });
          if (!this.noReloadOnDelete) {
            setTimeout(this.doLoad, 1000);
          }
        }
      });
    },
    actContext: function (row, event, expanded, col) {
      this.$emit('contextmenu', row, event, expanded, col);
      this.$emit('click', row, col);

      if (this.disableContextMenu || (this.disableActContextOnClick && event.type === 'click')) {
        return;
      }

      // 100ms para garantir que qualquer fn chamada por contextmenu tenha efeito antes de renderizar o menu de contexto.
      setTimeout(() => {
        const menu = Array.isArray(this.expandContextMenu) && expanded ? this.expandContextMenu : this.defaultOptions;
        this.showContext({
          left: event.clientX,
          top: event.clientY,
          val: row,
          col,
          menu,
        });
      }, 100);
    },
    updateFilterTrimestre: function(filterTrimestre) {
      this.filterTrimestre = filterTrimestre;
    },
    updateBeneficio: function(beneficio) {
      this.beneficio = beneficio;
      this.$emit('update:beneficio', beneficio);
    },
    updateMonthlyFilter: function(monthlyFilter) {
      const [ini, fim] = monthlyFilter;
      this.competencia = !fim ? [ini] : ini > fim ? [fim, ini] : [ini, fim];
      this.$emit('update:monthlyFilter', monthlyFilter);
    },
    updateOneMonthFilter: function(oneMonthFilter) {
      this.competencia = [oneMonthFilter, oneMonthFilter];
      this.$emit('update:oneMonthFilter', oneMonthFilter);
    },
    updateYearFilter: function(year) {
      this.anoBase = year;
      this.$emit('update:yearFilter', year);
    },
    updateDayFilter: function(dayFilter) {
      const [ini, fim] = dayFilter;
      this.competencia = !fim ? [ini] : ini > fim ? [fim, ini] : [ini, fim];
      this.$emit('update:dayFilter', dayFilter);
    },
  },
  mounted: function () {
    this.$emit('mounted', {
      doLoad: this.doLoad,
    });
  },
  watch: {
    anoBase: function (anoBase) {
      this.setFilters({ anoBase });
      this.doLoad();

      if (this.selectionCol) {
        this.$emit('update:selection', []);
      }
    },
    competencia: function (monthly, oldValue) {
      const [mI, mF] = monthly;
      const [oI, oF] = oldValue;

      if (mI !== oI || mF !== oF) {
        this.setFilters({ monthly });
        this.doLoad();

        if (this.selectionCol) {
          this.$emit('update:selection', []);
        }
      }
    },
    filterQuery: function () {
      this.doLoad();

      if (this.selectionCol) {
        this.$emit('update:selection', []);
      }
    },
    resourceUrl: function () {
      this.doLoad();

      if (this.selectionCol) {
        this.$emit('update:selection', []);
      }
    },
    beneficio: function () {
      this.doLoad();

      if (this.selectionCol) {
        this.$emit('update:selection', []);
      }
    },
    customResource: function () {
      this.doLoad();

      if (this.selectionCol) {
        this.$emit('update:selection', []);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.v-card.v-sheet::v-deep .v-data-table__empty-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%)
}
</style>
